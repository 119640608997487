<template>
  <v-container>
    <BaseScreenHeader
      title="Resource Not Found"
      screenInfo="404"
      width="90%"
      :showBack="false"
    />

    <v-card-actions class="pa-0">
      <v-btn x-large icon class="py-2 silver--text" :to="{ name: 'home' }">
        <v-icon x-large>mdi-home-variant-outline</v-icon>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-card-text class="text-center pa-0">
      <span class="display-4 silver--text"> 404 error</span>
      <div class="silver--text display-1 text-center mt-10">
        You are doing it wrong!
      </div>
      <v-card height="300" class="paper" :elevation="0">
        <v-card-subtitle class="body-1 px-2 mt-5">
          The {{ resource }} you are looking for is not here. This is probably
          caused by your lack of navigation skills.
        </v-card-subtitle>
        <v-card-subtitle class="px-2 body-1 charcoal--text">
          Or you messed with it. Did you mess with it?
        </v-card-subtitle>
      </v-card>
    </v-card-text>
  </v-container>
</template>
<script>
export default {
  props: {
    resource: {
      type: String,
      required: true
    }
  }
}
</script>
